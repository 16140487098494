'use strict'

const _ = require('lodash')
const {stripHashIfExists} = require('../../helpers/dataUtils')

const PRO_GALLERY_APP_DEF_ID = '14271d6f-ba62-d045-549b-ab972ae1f70e'
const PRO_GALLERY_FULL_SCREEN_WIDGET_ID = '144f04b9-aab4-fde7-179b-780c11da4f46'
const PRO_GALLERY_TPA_PAGE_ID = 'fullscreen_page'

const TPA_MULTI_SECTION_COMP_TYPE = 'wysiwyg.viewer.components.tpapps.TPAMultiSection'

const getBlankBackground = () => ({
    type: 'BackgroundMedia',
    color: 'transparent',
    alignType: 'center',
    fittingType: 'fill',
    scrollType: 'none',
    mediaRef: null
})

const getEmptyPageStyle = id => ({
    type: 'ComponentStyle',
    id,
    style: {
        properties: {},
        propertiesSource: {},
        groups: {}
    },
    componentClassName: 'mobile.core.components.Page',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.page.TransparentPageSkin'
})

const updatePageStyle = (pageJson, uniqueIdGenerator) => {
    const newId = uniqueIdGenerator.getUniqueId('style', '-')
    const style = getEmptyPageStyle(newId)
    pageJson.structure.styleId = newId
    pageJson.data.theme_data[newId] = style
}

const handleFixInViewer = (pageJson, proGalleryApplicationId, uniqueIdGenerator) => {
    const pageId = _.get(pageJson, 'structure.id')
    const pageData = _.get(pageJson, ['data', 'document_data', pageId])
    const tpaPageId = _.get(pageData, 'tpaPageId')
    const tpaApplicationId = _.get(pageData, 'tpaApplicationId')
    if (tpaPageId === PRO_GALLERY_TPA_PAGE_ID && tpaApplicationId === proGalleryApplicationId) {
        updateBackground(pageData, pageJson, 'desktop')
        updateBackground(pageData, pageJson, 'mobile')
        updatePageStyle(pageJson, uniqueIdGenerator)
    }
}

function updateBackground(pageData, pageJson, viewMode) {
    let backgroundRef = _.get(pageData, ['pageBackgrounds', viewMode, 'ref'])
    backgroundRef = _.replace(backgroundRef, '#', '')
    const backgroundData = _.get(pageJson, ['data', 'document_data', backgroundRef])
    if (backgroundData) {
        _.merge(backgroundData, getBlankBackground())
    }
}

function handleFixInEditor(pageJson, proGalleryApplicationId, uniqueIdGenerator) {
    const pageId = _.get(pageJson, 'structure.id')

    if (pageId === 'masterPage') {
        const masterPageData = _.get(pageJson, 'data.document_data')
        const pageData = _.find(masterPageData, {tpaApplicationId: proGalleryApplicationId, tpaPageId: PRO_GALLERY_TPA_PAGE_ID})
        if (pageData) {
            updateBackground(pageData, pageJson, 'desktop')
            updateBackground(pageData, pageJson, 'mobile')
        }
    } else {
        handleNonMasterPageFixInEditor(pageJson, uniqueIdGenerator)
    }
}

function handleNonMasterPageFixInEditor(pageJson, uniqueIdGenerator) {
    const pageDirectComponents = _.get(pageJson, ['structure', 'components'])
    const TPAMultiSectionComponent = _.find(pageDirectComponents, {componentType: TPA_MULTI_SECTION_COMP_TYPE})
    if (TPAMultiSectionComponent) {
        const compDataQuery = stripHashIfExists(TPAMultiSectionComponent.dataQuery)
        const tpaComponentWidgetId = _.get(pageJson, ['data', 'document_data', compDataQuery, 'widgetId'])
        if (tpaComponentWidgetId === PRO_GALLERY_FULL_SCREEN_WIDGET_ID) {
            updatePageStyle(pageJson, uniqueIdGenerator)
        }
    }
}

function getProGalleryApplicationId(clientSpecMap) {
    const appData = _.find(clientSpecMap, {appDefinitionId: PRO_GALLERY_APP_DEF_ID})
    return _.get(appData, 'applicationId')
}

module.exports = {
    /*
     * fix pro gallery full screen page to have a blank background to avoid the site background image flashing before the iframe loads
     * Need to update background data on both master page and the actual page due to differences in data location between editor and viewer
     */
    exec(pageJson, pageIdsArray, magicObject) {
        const proGalleryApplicationId = getProGalleryApplicationId(magicObject.clientSpecMap)
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        if (proGalleryApplicationId) {
            if (magicObject.isViewerMode) {
                handleFixInViewer(pageJson, proGalleryApplicationId, uniqueIdGenerator)
            } else {
                handleFixInEditor(pageJson, proGalleryApplicationId, uniqueIdGenerator)
            }
        }
    }
}
