'use strict'

const _ = require('lodash')

const createLayoutItemForCompOrOverride = (obj, layoutDataMap, uniqueIdGenerator) => {
    const {layout} = obj
    delete obj.layout
    const dataId = uniqueIdGenerator.getUniqueId('layoutItem', '-')
    const newDataItem = _.defaults({id: dataId, type: 'AbsoluteLayout'}, layout)
    obj.layoutQuery = `#${dataId}`
    layoutDataMap[dataId] = newDataItem
}

const fixLayoutForComp = (comp, layoutDataMap, uniqueIdGenerator) => {
    if (comp.layoutQuery) {
        delete comp.layout
        return false
    }
    if (comp.layout) {
        createLayoutItemForCompOrOverride(comp, layoutDataMap, uniqueIdGenerator)
    }
    if (comp.modes && comp.modes.overrides) {
        comp.modes.overrides.forEach(override => {
            if (override.layout) {
                createLayoutItemForCompOrOverride(override, layoutDataMap, uniqueIdGenerator)
            }
        })
    }
    return true
}

const fixLayoutForComps = (components, layoutDataMap, uniqueIdGenerator) => {
    _.forEach(components, comp => {
        fixLayoutForComp(comp, layoutDataMap, uniqueIdGenerator)
        fixLayoutForComps(comp.components, layoutDataMap, uniqueIdGenerator)
    })
}

/**
 * @exports utils/dataFixer/plugins/layoutDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson, pageIdsArray, magicObject) {
        const {isExperimentOpen, isViewerMode, dataFixerUtils} = magicObject
        const isMigrateAbsoluteLayoutToDataMaps = isExperimentOpen('bv_migrateAbsoluteLayoutToDataMaps')
        const {uniqueIdGenerator} = dataFixerUtils
        if (!isMigrateAbsoluteLayoutToDataMaps || !isViewerMode) {
            return
        }

        const structureData = pageJson.structure

        if (!pageJson.data.layout_data) {
            pageJson.data.layout_data = {}
        }
        const layoutDataMap = pageJson.data.layout_data

        const fixWasNeeded = fixLayoutForComp(pageJson.structure, layoutDataMap, uniqueIdGenerator)
        if (!fixWasNeeded) {
            return
        }
        if (structureData.components) {
            fixLayoutForComps(structureData.components, layoutDataMap, uniqueIdGenerator)
        }
        if (structureData.mobileComponents) {
            fixLayoutForComps(structureData.mobileComponents, layoutDataMap, uniqueIdGenerator)
        }
        if (structureData.children) {
            fixLayoutForComps(structureData.children, layoutDataMap, uniqueIdGenerator)
        }
    }
}
