'use strict'

const _ = require('lodash')
const {stripHashIfExists} = require('../helpers/dataUtils')

function moveSinglePostToTopLevel(masterPageData) {
    const singlePostPageId = findSinglePostPageMenuItem(masterPageData)
    if (singlePostPageId) {
        moveSinglePostToNewParent(masterPageData, `#${singlePostPageId}`)
    }
}

function findSinglePostPageMenuItem(data) {
    const singlePostPageId = _.findKey(data, key => key.appPageId && key.appPageId === '7326bfbb-4b10-4a8e-84c1-73f776051e10')

    if (!singlePostPageId) {
        return null
    }

    const singlePostLinkId = _.findKey(data, key => key.pageId && key.pageId === `#${singlePostPageId}`)
    return _.findKey(data, key => key.link && key.link === `#${singlePostLinkId}`)
}

function moveSinglePostToNewParent(data, singlePostPageId) {
    const pagesIds = data.CUSTOM_MAIN_MENU && data.CUSTOM_MAIN_MENU.items
    _.forEach(pagesIds, pageId => {
        const pageItems = data[stripHashIfExists(pageId)].items
        const index = _.indexOf(pageItems, singlePostPageId)
        if (index !== -1) {
            pageItems.splice(index, 1)
            data.CUSTOM_MAIN_MENU.items.push(singlePostPageId)
            return false
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/blogPageMenuFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson) {
        if (pageJson.structure && pageJson.structure.type === 'Document') {
            moveSinglePostToTopLevel(pageJson.data.document_data)
        }
        return pageJson
    }
}
