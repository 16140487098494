'use strict'

const _ = require('lodash')

const isJsonForPage = _.matchesProperty(['structure', 'type'], 'Page')

module.exports = {
    exec(pageJson, pageIdsArray, magicObject) {
        if (_.get(magicObject, 'isSlash') && !isJsonForPage(pageJson)) {
            _.forEach(magicObject.pageIdToResolvedUriSEO, (resolved, pageId) => {
                if (pageJson.data.document_data[pageId]) {
                    pageJson.data.document_data[pageId].pageUriSEO = resolved.curr
                }
            })
        }
    }
}
