'use strict'

const coreUtils = require('@wix/santa-core-utils')
const {getThemeData} = require('../helpers/themeDataUtils')

const EXPERIMENT_NAME = 'dm_migrateToTextTheme'

const enrichThemeDataWithTextThemes = themeData => {
    const {font, textTheme} = themeData

    if (!textTheme || !textTheme.length) {
        themeData.textTheme = font.map(fontString =>
            coreUtils.fonts.fontStringToTextTheme(fontString)
        )
    }
}

const cleanupThemeDataOfTextThemes = themeData => {
    if (themeData.textTheme) {
        delete themeData.textTheme
    }
}

module.exports = {
    exec(pageJson, pageIdsArray, magicObject) {
        if (pageJson.structure.type === 'Document') {
            const themeData = getThemeData(pageJson)
            if (themeData) {
                if (magicObject.isExperimentOpen(EXPERIMENT_NAME)) {
                    enrichThemeDataWithTextThemes(themeData)
                } else {
                    cleanupThemeDataOfTextThemes(themeData)
                }
            }
        }
        return pageJson
    },
    EXPERIMENT_NAME
}
