'use strict'
const _ = require('lodash')
const dataUtils = require('../helpers/dataUtils')
const coreUtils = require('@wix/santa-core-utils')

/**
 * this fixer removes corrupted data related to responsive layout structure
 * it handles this cases:
 * 1) breakpointRelation that points to a non-existing breakpoint
 * 2) imageX scoped data that points to a non-existing breakpoint
 * 3) refArray that points to a non-existing data item
 * 4) 2 different refArrays that points to the same dataItem id
 * 5) ref Array that points twice to the same id
 * 6) orphan breakpointRelation (no refArray that points to it) - #DM-3592
 * see example sites here - #DM-3106, #DM-3247
*/

const removeCorruptedRelation = (dataMapToFix, relationData) => {
    const relationRefId = dataUtils.stripHashIfExists(relationData.ref)
    if (relationRefId) {
        delete dataMapToFix[relationRefId]
    }
    delete dataMapToFix[relationData.id]
}

const removeRefArrayMissingItem = (dataMapToFix, refArray, idToRemove) => {
    const newValues = _.difference(refArray.values, [`#${idToRemove}`])
    dataMapToFix[refArray.id] = dataUtils.refArray.update(refArray, newValues)
}

const removeRefArrayDuplicatedIds = (dataMapToFix, refArray) => {
    const newValues = _.uniq(refArray.values)
    dataMapToFix[refArray.id] = dataUtils.refArray.update(refArray, newValues)
}

const duplicateDataItemAndUpdateRefArray = (dataMapToFix, dataType, refArray, oldDataItem, uniqueIdGenerator) => {
    const newId = uniqueIdGenerator.getUniqueId(dataType, '-', {bucket: dataType})
    const newDataItem = _.assign({}, oldDataItem, {id: newId})

    if (dataUtils.breakpointRelation.isBreakpointRelation(oldDataItem)) {
        const refId = dataUtils.stripHashIfExists(oldDataItem.ref)
        const oldRefItem = dataMapToFix[refId]
        const newRefId = uniqueIdGenerator.getUniqueId(dataType, '-', {bucket: dataType})
        newDataItem.ref = `#${newRefId}`
        dataMapToFix[newRefId] = _.assign({}, oldRefItem, {id: newRefId})
    }

    dataMapToFix[newId] = newDataItem

    const newValues = _(refArray.values).replace(`#${oldDataItem.id}`, `#${newId}`).split(',')
    dataMapToFix[refArray.id] = dataUtils.refArray.update(refArray, newValues)
    return newId
}


// handle missing ref item or same data item ids
const handleRefArraysIssues = (pageJson, dataType, uniqueIdGenerator) => {
    const dataMap = coreUtils.constants.PAGE_DATA_DATA_TYPES[dataType]
    const dataMapToFix = pageJson.data[dataMap]
    const refArrayNodes = _.pickBy(dataMapToFix, dataUtils.refArray.isRefArray)

    const refArrValuesMap = {}

    _.forEach(refArrayNodes, refArray => {
        const values = dataUtils.refArray.extractValuesWithoutHash(refArray)
        _.forEach(values, dataId => {
            // missing ref
            if (!_.has(dataMapToFix, dataId)) {
                removeRefArrayMissingItem(dataMapToFix, refArray, dataId)
            // multiple refArrays with same value || same RefArray duplicated ids
            } else if (_.has(refArrValuesMap, dataId)) {
                const isSameRefArray = refArrValuesMap[dataId] === refArray.id
                if (!isSameRefArray) {
                    const oldDataItem = dataMapToFix[dataId]
                    const newDataItemId = duplicateDataItemAndUpdateRefArray(dataMapToFix, dataType, refArray, oldDataItem, uniqueIdGenerator)
                    refArrValuesMap[newDataItemId] = refArray.id
                } else {
                    removeRefArrayDuplicatedIds(dataMapToFix, refArray)
                }
            } else {
                refArrValuesMap[dataId] = refArray.id
            }
        })
    })
    return refArrValuesMap
}


const handleRelationsBreakpointMissingRef = (pageJson, dataType, pageBpRanges, refArrayValuesToRefArrayId) => {
    const dataMap = coreUtils.constants.PAGE_DATA_DATA_TYPES[dataType]
    const dataMapToFix = pageJson.data[dataMap]
    const breakpointRelationNodes = _.pickBy(dataMapToFix, dataUtils.breakpointRelation.isBreakpointRelation)

    _.forEach(breakpointRelationNodes, relation => {
        const breakpointId = relation.breakpoint && dataUtils.stripHashIfExists(relation.breakpoint)
        const isBPRangeExist = !!pageBpRanges[breakpointId]
        const refId = dataUtils.stripHashIfExists(relation.ref)
        const isRefExist = !!dataMapToFix[refId]

        const refArrayId = refArrayValuesToRefArrayId[dataType][relation.id]
        const refArray = dataMapToFix[refArrayId]

        if (!isBPRangeExist || !isRefExist || !refArray) {
            removeCorruptedRelation(dataMapToFix, relation)

            if (refArray) {
                removeRefArrayMissingItem(dataMapToFix, refArray, relation.id)
            }
        }
    })
}

const handleImageXMissingBreakpointRef = (pageJson, pageBpRanges) => {
    const dataMapToFix = pageJson.data.document_data
    const imageXDataNodes = _.pickBy(dataMapToFix, {type: 'ImageX'})

    _.forEach(imageXDataNodes, (val, id) => {
        const validScopedData = _.filter(val.scopedData, data => {
            const breakpointId = data.breakpoint && dataUtils.stripHashIfExists(data.breakpoint)
            return !breakpointId || !!pageBpRanges[breakpointId]
        })
        dataMapToFix[id] = _.assign({}, val, {scopedData: validScopedData})
    })
}



module.exports = {
    exec: (pageJson, pageIdsArray, magicObject) => {
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        const breakpointsMap = _.get(pageJson.data, 'breakpoints_data')

        if (!_.isEmpty(breakpointsMap)) {
            const pageBreakpointsRanges = _.pickBy(breakpointsMap, {type: 'BreakpointRange'})
            const refArrayValuesToRefArrayId = {}

            refArrayValuesToRefArrayId.layout = handleRefArraysIssues(pageJson, 'layout', uniqueIdGenerator)

            handleRelationsBreakpointMissingRef(pageJson, 'layout', pageBreakpointsRanges, refArrayValuesToRefArrayId)

            handleImageXMissingBreakpointRef(pageJson, pageBreakpointsRanges)
        }
    }
}
