'use strict'

const _ = require('lodash')
const {CompsMigrationHelper} = require('../helpers/CompsMigrationHelper')

const SEMI_NATIVE_STYLE_POSTFIX = '-semi'
const TPA_WIDGET = 'wysiwyg.viewer.components.tpapps.TPAWidget'

function getSemiNativeWidgetsIds(clientSpecMap) {
    return _(clientSpecMap)
        .filter('appFields.platform')
        .reduce((acc, entry) => _(entry.widgets)
            .pickBy(['componentFields.semiNative', true])
            .mapValues(_.constant(true))
            .assign(acc)
            .value(), {})
}

function addSemiNativeStyleForEveryTpaWidgetStyle(pageJson) {
    const newStyles = _(pageJson.data.theme_data)
        .filter({
            componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin'
        })
        .map(style => {
            const newStyle = _.cloneDeep(style)
            newStyle.componentClassName = 'platform.components.semiNativeComponent'
            newStyle.skin = 'wysiwyg.viewer.skins.semiNativeComponentSkin'
            return newStyle
        })
        .transform((res, style) => {
            res[style.id + SEMI_NATIVE_STYLE_POSTFIX] = style
        }, {})
        .value()
    _.assign(pageJson.data.theme_data, newStyles)
}

function migrateTpaWidgetIfSemiNative(clientSpecMap, page, cache, mobileView, comp) {
    const semiNativeWidgetIds = getSemiNativeWidgetsIds(clientSpecMap)
    const data = this.getComponentData(page, comp)
    const shouldMigrateComp = comp.componentType === TPA_WIDGET && semiNativeWidgetIds[data.widgetId]

    if (shouldMigrateComp) {
        comp.componentType = 'platform.components.semiNativeComponent'
        comp.skin = 'wysiwyg.viewer.skins.semiNativeComponentSkin'
        comp.styleId += SEMI_NATIVE_STYLE_POSTFIX

        this.setComponentData(page, comp, _.defaults({
            type: 'SemiNative'
        }, data), cache)
    }
}

/**
 * @exports utils/dataFixer/plugins/migrateWidgetsToControllers
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson, pageIdsArray, magicObject) {
        const isMigrateTpaToSemiNativeOpen = magicObject.isExperimentOpen('sv_migrateTpaToSemiNative')
        if (isMigrateTpaToSemiNativeOpen && magicObject.isViewerMode) {
            if (pageJson.structure.id === 'masterPage') {
                addSemiNativeStyleForEveryTpaWidgetStyle(pageJson)
            }

            const MIGRATION_MAP = {
                'wysiwyg.viewer.components.tpapps.TPAWidget': _.partial(migrateTpaWidgetIfSemiNative, magicObject.clientSpecMap)
            }

            const {uniqueIdGenerator} = magicObject.dataFixerUtils
            const migrationHelper = new CompsMigrationHelper(MIGRATION_MAP, uniqueIdGenerator)
            migrationHelper.migratePage(pageJson)
        }
    }
}
