'use strict'

const _ = require('lodash')
const behaviorsMigrationHelper = require('../helpers/behaviorsMigrationHelper')
const {stripHashIfExists} = require('../helpers/dataUtils')

const compTypes = [
    'wysiwyg.viewer.components.StripContainer',
    'wysiwyg.viewer.components.StripContainerSlideShowSlide'
]

const effectsMap = {
    fixed: {action: 'bgScrub', name: 'BackgroundReveal', duration: 1, delay: 0},
    parallax: {action: 'bgScrub', name: 'BackgroundParallax', duration: 1, delay: 0}
}

function getCompBehaviors(comp, behaviorsData) {
    const behaviorsStr = _.get(behaviorsData, [comp.behaviorQuery, 'items'], '[]')
    return JSON.parse(behaviorsStr)
}

function getContainerBgData(comp, data) {
    // Get Data Item
    const dataRef = stripHashIfExists(comp.designQuery)
    const bgRef = stripHashIfExists(data[dataRef].background)
    return data[bgRef]
}

function changeScrollTypeToBehavior(comp, data, behaviorsData, uniqueIdGenerator) {
    let behaviors

    if (!_.includes(compTypes, comp.componentType)) {
        return
    }

    const containerBgData = getContainerBgData(comp, data)
    const scrollType = containerBgData.scrollType || 'none'
    containerBgData.scrollType = 'none'

    // If scrollType === scroll just set to 'none' else add/or remove ContainerFixedBg behavior
    if (scrollType !== 'scroll' && scrollType !== 'none' && effectsMap[scrollType]) {
        behaviors = getCompBehaviors(comp, behaviorsData)
        behaviors = _.reject(behaviors, {name: 'ContainerFixedBg', action: 'bgScrub'})
        behaviors.push(effectsMap[scrollType])
        const behaviorsToSet = JSON.stringify(behaviors)

        if (comp.behaviorQuery) {
            _.set(behaviorsData, [comp.behaviorQuery, 'items'], behaviorsToSet)
        } else {
            const behaviorsDataItem = behaviorsMigrationHelper.createBehaviorsDataItem(behaviorsToSet, uniqueIdGenerator)
            _.set(behaviorsData, behaviorsDataItem.id, behaviorsDataItem)
            comp.behaviorQuery = behaviorsDataItem.id
        }
    }
}

function removeScrollTypeOldBehavior(comp, data, behaviorsData) {
    let behaviors

    if (!_.includes(compTypes, comp.componentType)) {
        return
    }

    const containerBgData = getContainerBgData(comp, data)
    containerBgData.scrollType = 'none'

    behaviors = getCompBehaviors(comp, behaviorsData)
    behaviors = _.reject(behaviors, {name: 'ContainerFixedBg', action: 'bgScrub'})
    if (_.isEmpty(behaviors)) {
        delete comp.behaviors
        delete comp.behaviorQuery
    } else {
        _.set(behaviorsData, [comp.behaviorQuery, 'items'], JSON.stringify(behaviors))
    }
}

function fixBackgroundEffect(comps, data, behaviorsData, uniqueIdGenerator) {
    _.forEach(comps, comp => {
        changeScrollTypeToBehavior(comp, data, behaviorsData, uniqueIdGenerator)
        if (comp.components) {
            fixBackgroundEffect(comp.components, data, behaviorsData, uniqueIdGenerator)
        }
    })
}

function removeOldBackgroundEffect(comps, data, behaviorsData) {
    _.forEach(comps, comp => {
        removeScrollTypeOldBehavior(comp, data, behaviorsData)
        if (comp.components) {
            removeOldBackgroundEffect(comp.components, data, behaviorsData)
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/behaviorsFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson, pageIdsArray, magicObject) {
        const structureData = pageJson.structure
        if (!structureData) {
            return
        }

        const data = pageJson.data.design_data
        const behaviorsData = pageJson.data.behaviors_data
        const {uniqueIdGenerator} = magicObject.dataFixerUtils

        //Page
        if (structureData.components) {
            fixBackgroundEffect(structureData.components, data, behaviorsData, uniqueIdGenerator)
        }
        //MasterPage
        if (structureData.children) {
            fixBackgroundEffect(structureData.children, data, behaviorsData, uniqueIdGenerator)
        }
        // Mobile - Remove!
        if (structureData.mobileComponents) {
            removeOldBackgroundEffect(structureData.mobileComponents, data, behaviorsData)
        }
    }
}
