'use strict'

const _ = require('lodash')
const {publicDataMaps} = require('./publicNamespaces')

module.exports = {
    exec: (pageJson, pageIdsArray, magicObject) => {
        if (magicObject.isViewerMode) {
            pageJson.data = _.pick(pageJson.data, publicDataMaps)
        }
    }
}
