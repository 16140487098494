'use strict'

const _ = require('lodash')

const BG_MEDIA_TYPE = 'BackgroundMedia'

const BG_ITEM_TEMPLATE = {
    alignType: 'center',
    color: '{color_11}',
    fittingType: 'fill',
    scrollType: 'scroll',
    type: 'BackgroundMedia'
}

const PAGE_BACKGROUNDS_TEMPLATE = {
    desktop: {
        custom: true,
        isPreset: true,
        ref: null
    },
    mobile: {
        custom: true,
        isPreset: true,
        ref: null
    }
}

function generateBgItemId(idPrefix, item, uniqueIdGenerator) {
    return uniqueIdGenerator.getUniqueId(item.id, '_') + idPrefix
}

function addNewBgItem(id, pageData) {
    pageData[id] = _.assign({id}, BG_ITEM_TEMPLATE)
}

/**
 * For each page, check if mobile bg is defined, and if not, fill in with a basic bg object.
 * @param pageData
 */
function fixUndefinedBackgroundMediaItems(pageData, uniqueIdGenerator) {
    const pageDataItems = _.filter(pageData, item => item.type === 'Page' || item.type === 'AppPage')

    _.forEach(pageDataItems, item => {
        if (!item.pageBackgrounds) {
            item.pageBackgrounds = _.cloneDeep(PAGE_BACKGROUNDS_TEMPLATE)
        }
        ['mobile', 'desktop'].forEach(device => {
            if (_.has(item, ['pageBackgrounds', device]) && !_.get(item, ['pageBackgrounds', device, 'ref'])) {
                const id = generateBgItemId(`_${device}_bg`, item, uniqueIdGenerator)
                addNewBgItem(id, pageData)
                item.pageBackgrounds[device].ref = `#${id}`
            }
        })
    })
}



/**
 * @exports utils/dataFixer/plugins/backgroundMediaFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson, pageIdsArray, magicObject) {
        if (!pageJson.structure) {
            return
        }

        const data = pageJson.data.document_data
        const hasBgMedia = _.some(data, {type: BG_MEDIA_TYPE})
        const {uniqueIdGenerator} = magicObject.dataFixerUtils

        // Run ONLY if we have at least one BackgroundMedia item
        if (hasBgMedia) {
            fixUndefinedBackgroundMediaItems(data, uniqueIdGenerator)
        }
    }
}
